import "../styles/app.scss";

// /*
//  * NOTE!
//  * app-critical.js must be loaded in the browser before this file
//  * =========================================================================== */

// /*
//  * Bootstrap Components
//  * Only uncomment what you need to reduce js payload size
//  * =========================================================================== */
// // import Alert from 'bootstrap/js/dist/alert'
// // import Button from 'bootstrap/js/dist/button'
// // import Carousel from 'bootstrap/js/dist/carousel'
// // import Collapse from 'bootstrap/js/dist/collapse'
// // import Dropdown from 'bootstrap/js/dist/dropdown'
// import Modal from "bootstrap/js/dist/modal";
// // import Modal from 'bootstrap/js/dist/offcanvas'
// // import Popover from 'bootstrap/js/dist/popover'
// // import Scrollspy from 'bootstrap/js/dist/scrollspy'
// // import Tab from 'bootstrap/js/dist/tab'
// // import Toast from 'bootstrap/js/dist/toast'
// // import Tooltip from 'bootstrap/js/dist/tooltip'

// /*
//  * Other 3rd Party Dependencies
//  * =========================================================================== */
// // import accessibleAutocomplete from 'accessible-autocomplete';
// import _ from "lodash";
// import reframe from "reframe.js";

// /*
//  * Custom Dependencies
//  * =========================================================================== */

// // Import Non-Critical JS Components
import App__MailChimp from "./components/MailChimp";
import App__AwardsFilters from "./components/AwardsFilters";
import App__PredictiveSearch from "./components/PredictiveSearch";
import App__DisruptiveCta from "./components/DisruptiveCta";
import App__VoteForm from "./components/VoteForm";
import App__Calendar from "./components/Calendar";

// // Import Non-Critical JS Utilities
// import App__pagination from "./utilities/pagination";
// import App__sibling from "./utilities/sibling";
// import App__urlToolkit from "./utilities/url-toolkit";

// /*
//  * Update the the global App object (App object is setup in app-critical.js)
//  * =========================================================================== */

// // Add Components to App
App.MailChimp = new App__MailChimp();
App.AwardsFilters = new App__AwardsFilters();
App.PredictiveSearch = new App__PredictiveSearch();
App.DisruptiveCta = new App__DisruptiveCta();
App.VoteForm = new App__VoteForm();
App.Calendar = new App__Calendar();

// // Add Utils to App
// App.utils.pagination = App__pagination;
// App.utils.sibling = App__sibling;
// App.utils.urlToolkit = App__urlToolkit;

// App.modal = {};

// // Global App component initialization
App.init = function () {
  App.MailChimp.init();
  App.AwardsFilters.init();
  App.PredictiveSearch.init();
  App.DisruptiveCta.init();
  App.VoteForm.init();
};

App.Calendar.init();
window.addEventListener("load", function (event) {
  App.init();
});

// // window.addEventListener('resize', function( event) {
// // });
