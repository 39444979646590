import { hasClass, addClass, removeClass } from "../utilities/dom-toolkit";
import {
  getParameterByName,
  removeURLParameter,
  updateQueryStringParameter,
} from "../utilities/url-toolkit";

export default class App__AwardsFilters {
  constructor() {
    this.categoriesFilters = document.querySelectorAll(
      "#awards-recipients-categories input[type='radio']"
    );
    this.yearsFilters = document.querySelector(
      "#awards-recipients-years .select"
    );
    this.pathname = window.location.href;
    this.filterBoxes = document.querySelectorAll(
      ".awards-recipients fieldset legend"
    );
    this.showingCategoryBox = document.querySelector("#showing-category");
  }

  init() {
    // Collapse Filters
    for (let i = this.filterBoxes.length - 1; i >= 0; i--) {
      const element = this.filterBoxes[i];
      element.onclick = () => {
        if (hasClass(element.parentElement, "collapsed")) {
          removeClass(element.parentElement, "collapsed");
        } else {
          addClass(element.parentElement, "collapsed");
        }
      };
    }

    if (getParameterByName("year") || getParameterByName("category")) {
      window.location.hash = "#jump";
    }

    this.initYearsFilters();
    this.initCategoriesControl();
  }

  initYearsFilters() {
    if (this.yearsFilters) {
      if (getParameterByName("year")) {
        this.yearsFilters.value = getParameterByName("year");
      }

      this.yearsFilters.onchange = () => {
        const value = this.yearsFilters.value;

        this.pathname = removeURLParameter(this.pathname, "page");

        if (value == "current-year") {
          this.pathname = removeURLParameter(this.pathname, "year");
        } else if (value == "all-years") {
          this.pathname = removeURLParameter(this.pathname, "year");
        } else {
          this.pathname = updateQueryStringParameter(
            this.pathname,
            "year",
            value
          );
        }
        window.location.href = this.pathname;
      };
    }
  }

  initCategoriesControl() {
    if (getParameterByName("category")) {
      const activeCategory = document.getElementById(
        getParameterByName("category")
      );

      if (activeCategory) {
        activeCategory.checked = true;

        this.showingCategoryBox.innerHTML = document.querySelector(
          '[for="' + getParameterByName("category") + '"]'
        ).innerHTML;
      }
    }

    for (let i = this.categoriesFilters.length - 1; i >= 0; i--) {
      const element = this.categoriesFilters[i];

      element.onclick = () => {
        this.pathname = removeURLParameter(this.pathname, "page");

        if (element.getAttribute("id") == "all-categories") {
          this.pathname = removeURLParameter(this.pathname, "category");
        } else {
          this.pathname = updateQueryStringParameter(
            this.pathname,
            "category",
            element.getAttribute("id")
          );
        }
        window.location.href = this.pathname;
      };
    }
  }
}
