export default class App__DisruptiveCta {
  activeElement;
  STORAGE_KEY = "seenCTA";

  constructor() {
    this.cta = document.querySelector(".disruptive-cta");
    this.link = document.querySelector(".disruptive-cta-close-link");
  }

  init() {
    if (
      this.cta &&
      this.link &&
      sessionStorage.getItem(this.STORAGE_KEY) === null
    ) {
      this.bindedHandleScroll = this.handleScrollPosition.bind(this);
      document.addEventListener("scroll", this.bindedHandleScroll);
    }
  }

  handleScrollPosition() {
    const percentage = this.getScrollPercentage();

    if (percentage > 60) {
      sessionStorage.setItem(this.STORAGE_KEY, true.toString());

      document.removeEventListener("scroll", this.bindedHandleScroll);

      this.activeElement = document.activeElement;

      this.cta.style.display = "block";
      this.cta.className += " active ";

      // Set focus on the close link, so that the user can immediately dismiss the CTA
      this.link.focus();

      // Register [Esc] key
      document.addEventListener("keyup", this.closeOnEscape.bind(this));

      // Register link itself
      this.link.addEventListener("click", this.handleLinkClick.bind(this));
    }
  }

  closeOnEscape(e) {
    if (e.keyCode === 27) {
      document.removeEventListener("keyup", this.closeOnEscape);
      this.cta.style.display = "none";
      this.cta.className = "disruptive-cta";
      this.restoreFocus();
    }
  }

  handleLinkClick(e) {
    e.preventDefault();

    this.link.removeEventListener("click", this.handleLinkClick);
    this.cta.style.display = "none";
    this.cta.className = "disruptive-cta";
    this.restoreFocus();
  }

  restoreFocus() {
    this.activeElement.focus();
  }

  // http://stackoverflow.com/a/8028584/123297
  getScrollPercentage() {
    const h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight";

    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  }
}
