export default class App__Calendar {
    constructor() {
        this.buttons = $('.btn-calendar');
        this.page = document.querySelector('.main');
    }

    init() {
        if (this.buttons.length === 0) {
            return;
        }

        const formatScript = document.createElement("script");
        formatScript.setAttribute("src", "/dist/vendor/php-date-formatter.min.js");
        const dataTimePickerScript = document.createElement("script");
        dataTimePickerScript.setAttribute("src", "/dist/vendor/jquery.datetimepicker.js");
        dataTimePickerScript.onload = () => {
            this.setDateTimePicker();
            const config = {
                attributes: false,
                childList: true,
                subtree: true
            };
            const observer = new MutationObserver(callback);
            observer.observe(this.page, config);
        };
        document.body.appendChild(formatScript);
        document.body.appendChild(dataTimePickerScript);

        const callback = () => {
            this.buttons = $('.btn-calendar');
            this.setDateTimePicker();
        };
    }

    setDateTimePicker() {
        if (this.buttons === undefined || this.buttons.length === 0) {
            return;
        }
        
        for (i = 0; i < this.buttons.length; i++) {
            const input = $(this.buttons[i]).prev();
            const btnNow = $(this.buttons[i]).next();
            $(input[0]).datetimepicker({
                format: 'Y-m-d H:i'
            });
            this.buttons[i].onclick = (e) => {
                e.preventDefault();
                const input = $(e.target).prev();
                $(input[0]).focus();
            };
            $(input[0]).onfocus = () => {
                $(input[0]).datetimepicker("show");
            }
            btnNow[0].onclick = (e) => {
                e.preventDefault();
                $(input[0]).datetimepicker({
                    value: Date(),
                    format: 'Y-m-d H:i'
                });
            };
        }
    }
}
