export default class App__VoteForm {
  constructor() {
    this.formNode = document.querySelector("#vote-form");
    this.API_URL = "/api/v1/voting-form";
  }

  init() {
    if (!this.formNode) {
      return;
    }
    this.formNode.addEventListener("submit", (evt) => this.onFormSubmit(evt));
    this.errorEmailBox = this.formNode.querySelector("#error-email");
    this.errorCaptchaBox = this.formNode.querySelector("#error-captcha");
  }

  async onFormSubmit(evt) {
    evt.preventDefault();
    const data = this.serializeForm(evt.target);
    const response = await this.sendData(data);
    if (!response.ok) {
      alert('Voting form error... ');
      return;
    }
    const {success, errors, redirectURL} = await response.json();

    if (success && redirectURL) {
      window.location.href = redirectURL;
      return;
    }

    if (errors) {
      this.errorEmailBox.textContent = errors["error-email"];
      this.errorCaptchaBox.textContent = errors["error-captcha"];
    }
  }

  serializeForm(formNode) {
    return new FormData(formNode);
  }

  async sendData(data) {
    return await fetch(this.API_URL, {
      method: "POST",
      body: data,
    });
  }
}
