export default class App__MailChimp {
  constructor() {
    this.submitButton = document.getElementById("mc-embedded-subscribe");
  }

  init() {
    if (!this.submitButton) {
      return;
    }

    const form = document.getElementById('mail-chimp-signup');

    if (!form) {
      return;
    }

    // NOTE:
    // Those properties MUST reflect the state of the form and can be copied from the form builder.
    window["fnames"] = [
      "EMAIL",
      "FNAME",
      "LNAME",
      "POSTAL",
      "FULLNAMES2",
      "MMERGE7",
    ];
    window["ftypes"] = ["email", "text", "text", "text", "text", "text"];

    const MAILCHIMP_URL =
      "//canadashistory.us1.list-manage.com/subscribe/post?u=9f8132854db4c2a3b24732481&id=8145df6f6e";

    // Save default form settings
    const oldId = form.id;
    const oldAction = form.action;

    // Set
    form.className = "validate";
    form.id = "mc-embedded-subscribe-form";
    form.action = MAILCHIMP_URL;

    // Load the MailChimp validation script
    const validationScript = document.createElement("script");
    validationScript.setAttribute("src", "/dist/vendor/mc-validate.js");
    validationScript.onload = () => {
      // Cleanup fields before submitting the form
      window["mc"]["ajaxOptions"].beforeSubmit = (arr, $form, options) => {
        for (var i = arr.length - 1; i >= 0; i--) {
          const field = arr[i];
          const element = document.getElementsByName(field.name)[0];
          if (!/mce\-.+/.test(element.id)) {
            arr.splice(i, 1);
          }
        }
      };

      window["$"]('input[type="submit"]').click((e) => {
        const name = e.currentTarget.name;
        if (name !== "subscribe") {
          window["__doPostBack"](e.currentTarget.name);
        }
      });

      // Set page form back to its original values
      form.className = "";
      form.id = oldId;
      form.action = oldAction;
    };

    document.body.appendChild(validationScript);
  }
}
