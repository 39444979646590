import axios from "axios";
import _ from "lodash";

export default class App__PredictiveSearch {
  constructor() {
    this.interval = null;
    this.MIN_LENGTH_FOR_SEARCH = 3;
    this.ENDPOINT_URL = "/api/v1/predictive-search";
    this.DEBOUNCE_INTERVAL_IN_MS = 300;
  }

  init() {
    const smartSearchBox = document.getElementById("smart-search-box");
    if (smartSearchBox) {
      const smartSearchText = smartSearchBox.querySelector("#smart-search-text");
      const smartSearchSumbit = smartSearchBox.querySelector("#smart-search-submit");

      smartSearchSumbit.addEventListener("click", (evt) => {
        evt.preventDefault();
        this.goToSearchPage(smartSearchText.value);
      })

      smartSearchText.addEventListener("keyup", (evt) => {
        evt.preventDefault();
        if (evt.keyCode === 13) {
          this.goToSearchPage(smartSearchText.value);
        }
      })
    }
    // Get both search boxes (desktop and mobile)
    const searchBoxes = document.querySelectorAll(".searchBox");
    this.debouncedInputSearchHandler = _.debounce(
      this.onInputSearch,
      this.DEBOUNCE_INTERVAL_IN_MS
    );

    searchBoxes.forEach((searchBox) => {
      searchBox.addEventListener("keydown", () => this.waitForResults());

      const searchInput = searchBox.querySelector(".predictive-search-input");
      const searchResults = searchBox.querySelector(".predictiveSearchResults");
      const submit = searchBox.querySelector(".search-button");

      searchInput.addEventListener("input", (evt) => {
        this.debouncedInputSearchHandler(evt.target.value, searchResults);
      });

      document.addEventListener("click", (evt) => {
        if (evt.target.value !== searchResults) {
          this.hideResultsBox(searchResults);
        }
      });

      submit.addEventListener("click", (evt) => {
        evt.preventDefault();
        this.goToSearchPage(searchInput.value);
      });

      searchInput.addEventListener("keyup", (evt) => {
        evt.preventDefault();
        if (evt.keyCode === 13) {
          this.goToSearchPage(searchInput.value);
        }
      })
    });
  }

  goToSearchPage(searchValue) {
    window.location.href = `/search?searchtext=${searchValue}&searchmode=anyword`;
  }

  async onInputSearch(value, resultsBox) {
    if (!value.trim().length) {
      this.hideResultsBox(resultsBox);
      this.clearResultsBox(resultsBox);
      return;
    }

    if (value.trim().length < this.MIN_LENGTH_FOR_SEARCH) {
      return;
    }

    try {
      const lang = document.documentElement.lang;

      const response = await axios.post(
        this.ENDPOINT_URL,
        {
          SearchTerm: value,
        },
        {
          headers: {
            "Accept-Language": `${lang}-CA`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Predictive search error");
      }
      const predictiveResults = response?.data;

      if (predictiveResults && predictiveResults.length) {
        this.clearResultsBox(resultsBox);

        predictiveResults.forEach((result) => {
          const item = document.createElement("div");
          const link = document.createElement("a");

          item.appendChild(link);
          item.classList.add("search-result");
          item.role = "list-item";

          link.href = result.url;
          link.innerText = result.name;

          resultsBox.appendChild(item);
        });
        this.showResultsBox(resultsBox);
      }
    } catch (e) {
      throw new Error("Predictive search error", e);
    }
  }

  clearResultsBox(box) {
    while (box.firstChild) {
      box.removeChild(box.firstChild);
    }
  }

  showResultsBox(box) {
    box.style.display = "block";
  }

  hideResultsBox(box) {
    box.style.display = "none";
  }

  waitForResults() {
    // Wait for search results to come in... ZzzZzzz
    this.interval = setInterval(() => {
      let searchResults = document.querySelectorAll(
        ".predictiveSearchHolder .search-result"
      );

      // Cancel the interval once the results are there
      if (searchResults.length > 0) {
        clearInterval(this.interval);

        for (let i = 0; i < searchResults.length; i++) {
          let searchResult = searchResults[i];
          searchResult.addEventListener("keydown", (e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              e.target.click();
            }
          });
        }
      }
    }, 500);
  }
}
